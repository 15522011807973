import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyCCx_49N1mcUap2ijnysSc9rlc-_WmFyv4',
  authDomain: 'camelia-69abc.firebaseapp.com',
  projectId: 'camelia-69abc',
  storageBucket: 'camelia-69abc.appspot.com',
  messagingSenderId: '287783299761',
  appId: '1:287783299761:web:52ffc9a71575c0ff92d090',
  measurementId: 'G-4MTB1BHKVC'
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}

